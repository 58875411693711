<template>
  <el-dialog
    title="Detalhes do controle do veículo"
    top="35px"
    v-model="shouldShow"
    width="90%"
  >
    <template #default v-if="pageDisplay === 'info'">
      <el-descriptions
        :column="3"
        size="large"
        border
        v-show="pageDisplay === 'info'"
      >
        <el-descriptions-item>
          <template #label>Odômetro inicial:</template>
          {{ integerFormatter.format(Number(carControl?.odometer_start) || 0) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Odômetro final:</template>
          {{ integerFormatter.format(carControl?.odometer_end) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Data</template>
          {{ dateFormatterLong.format(new Date(carControl?.start_at || "")) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>Motorista</template>
          {{ carControl?.driver?.employee?.name }}
        </el-descriptions-item>
      </el-descriptions>
      <el-tabs class="mt-2" type="border-card" v-show="pageDisplay === 'info'">
        <el-tab-pane label="Localização"
          ><div id="mapDiv" style="height: 450px"></div
        ></el-tab-pane>
        <el-tab-pane label="Selfie"
          ><div style="height: 450px">
            <el-image
              style="width: 300; height: 400px"
              :src="getImageSrc(carControl?.image_selfie)"
              fit="contain"
            ></el-image></div
        ></el-tab-pane>
        <el-tab-pane label="Odômetro"
          ><div style="height: 450px">
            <el-image
              style="width: 300px; height: 400px"
              :src="getImageSrc(carControl?.image_odometer)"
              fit="contain"
            ></el-image></div
        ></el-tab-pane>
      </el-tabs>
    </template>
    <template #default v-else>
      <div>
        <el-row type="flex" justify="start"><h5>Odômetro inicial:</h5> </el-row>
        <base-input
          v-model="carControl_.odometer_start"
          type="money"
          :minimumFractionDigits="0"
          label="Total:"
        ></base-input>
      </div>
      <div>
        <el-row type="flex" justify="start"><h5>Odômetro final:</h5> </el-row>
        <base-input
          v-model="carControl_.odometer_end"
          type="money"
          :minimumFractionDigits="0"
          label="Total:"
        ></base-input>
      </div>
    </template>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button
          v-show="pageDisplay === 'info'"
          type="warning"
          plain
          @click="togglePageDisplay"
          >Editar</el-button
        >
        <el-button
          v-show="pageDisplay === 'edit'"
          type="warning"
          plain
          @click="togglePageDisplay"
          >Voltar</el-button
        >
        <el-button
          type="text"
          class="button"
          @click="shouldShow = false"
          v-show="pageDisplay === 'info'"
          >Fechar</el-button
        >
        <el-button
          type="text"
          class="button"
          :loading="isLoadingSave"
          v-show="pageDisplay === 'edit'"
          @click="saveCarControlExecution"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
import BaseInput from "../../components/BaseInput.vue";
import {
  dateFormatter,
  currencyFormatter,
  numberFormatter,
  integerFormatter,
  dateFormatterLong,
} from "../../utils/formatters";
import CarService from "../../services/cars";
import { notifySuccess, notifyError } from "../../utils/notifiers";

export default {
  props: ["carControl", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      pageDisplay: "info",
      isLoadingSave: false,
      numberFormatter,
      integerFormatter,
      dateFormatterLong,
      currencyFormatter,
      dateFormatter,
      accessToken:
        "pk.eyJ1IjoiamFjb25zbW9yYWlzIiwiYSI6ImNrNW1uMGNrMjExbGQzcG83M3EwNDJlcWYifQ.astqW0QEC039TVEbzfo-lA",
      mapBox: null,
      carControl_: {},
    };
  },
  mounted() {
    this.initializeMap();
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  watch: {
    carControl(v) {
      this.carControl_ = v;
    },
  },
  methods: {
    togglePageDisplay() {
      if (this.pageDisplay === "info") {
        this.pageDisplay = "edit";
        this.mapBox?.remove();
      } else {
        this.pageDisplay = "info";
        this.initializeMap();
      }
    },
    async saveCarControlExecution() {
      this.isLoadingSave = true;

      const { control, error } = await CarService(this.carControl?.car?.uid)
        .Control(this.carControl?.uid)
        .update(this.carControl_);

      if (control) {
        notifySuccess(control.message);
        this.$emit("close-modal");
        this.$emit("should-update");
      } else notifyError(error.message);

      this.isLoadingSave = false;
    },
    initializeMap() {
      this.mapBox?.remove();
      const self = this;
      setTimeout(() => {
        mapboxgl.accessToken = self.accessToken;
        self.mapBox = new mapboxgl.Map({
          container: "mapDiv",
          style: "mapbox://styles/jaconsmorais/ckw2qm6gk11bg15msgqn9ynji",
          center: [
            Number(self.carControl?.longitude),
            Number(self.carControl?.latitude),
          ],
          zoom: 15,
        });

        new mapboxgl.Marker({
          color: "#0d84ff",
          draggable: false,
        })
          .setLngLat([
            Number(self.carControl.execution?.longitude),
            Number(self.carControl.execution?.latitude),
          ])
          .addTo(self.mapBox);
      }, 100);
    },
    getImageSrc(imageUrl) {
      return `${this.$store.state.apiUrl.slice(
        0,
        this.$store.state.apiUrl.length - 1
      )}${imageUrl}`;
    },
    getPaddingLeftForSelectPrefix(prefix) {
      return `padding-left: ${(prefix?.length || 0) * 7.5}px;`;
    },
  },
  name: "DriverModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.checkbox-margin-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.avatar-uploader {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;

  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.mt-2 {
  margin-top: 8px;
}
.placeholder {
  margin: 40px 0 16px;
  line-height: 35px;
}
.input-prefix {
  margin-left: 10px !important;
}
</style>