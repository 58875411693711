<template>
  <el-dialog title="Cadastrar controle" v-model="shouldShow" width="90%">
    <form>
      <el-row :gutter="8" type="flex">
        <el-col :md="12"
          ><el-select v-model="car_control_.category" size="medium">
            <template #prefix>Categoria:</template>
            <el-option
              v-for="item in VehicleObsCategories"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="12"
          ><el-select
            remote
            clearable
            :loading="!control_categories"
            v-model="car_control_.control_category_id"
            @visible-change="fetchControlCategories"
            size="medium"
          >
            <template #prefix>Categoria da Manutenção:</template>
            <el-option
              v-for="item in ControlCategories"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="8" type="flex">
        <el-col :md="12">
          <el-select
            v-model="car_control_.car_id"
            size="medium"
            remote
            loading-text="Carregando lista de veículos"
            no-data-text="Não existe veículos cadastrados"
            :disabled="!!car"
            value-key="uid"
          >
            <template #prefix>Veículo:</template>
            <el-option
              v-for="item in Vehicles"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="12">
          <el-select
            v-model="car_control_.driver_id"
            @visible-change="fetchDrivers"
            value-key="uid"
            size="medium"
          >
            <template #prefix>Motorista:</template>
            <el-option
              v-for="item in Drivers"
              :key="item.uid"
              :label="item?.employee?.name"
              :value="item?.uid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-select
        remote
        filterable
        clearable
        v-model="car_control_.provider_id"
        :remote-method="fetchProviders"
        value-key="uid"
        :loading="!providers"
        size="medium"
      >
        <template #prefix>Fornecedor:</template>
        <el-option
          v-for="item in Providers"
          :key="item.uid"
          :label="item?.name"
          :value="item?.uid"
        >
        </el-option>
      </el-select>
      <el-row :gutter="8">
        <el-col :md="12">
          <el-date-picker
            v-model="car_control_.start_at"
            type="date"
            size="medium"
            placeholder="Saída:"
            :disabledDate="(v) => disableDate(v, 'start')"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="car_control_.complete_at"
            type="date"
            size="medium"
            :disabledDate="(v) => disableDate(v, 'end')"
            placeholder="Retorno:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="8">
          <el-select
            v-model="car_control_.expire_by"
            value-key="value"
            size="medium"
          >
            <template #prefix>Expira quando:</template>
            <el-option
              v-for="item in ExpirationOptions"
              :key="item.value"
              :label="item?.name"
              :value="item?.value"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :md="8" v-if="car_control_.expire_by === 'odometer'">
          <base-input
            label="Expiração do serviço.:"
            hint="1506460"
            type="money"
            :minimumFractionDigits="0"
            v-model="car_control_.expire_odometer"
          >
          </base-input>
        </el-col>
        <el-col :md="8" v-else>
          <el-date-picker
            v-model="car_control_.warranty_expires_at"
            type="date"
            size="medium"
            placeholder="Expiração do serviço:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
        <el-col :md="8">
          <el-date-picker
            v-model="car_control_.warranty_expires_at"
            type="date"
            size="medium"
            placeholder="Garantia do serviço:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <base-input
        label="Odômetro:"
        hint="1506460"
        type="money"
        :minimumFractionDigits="0"
        v-model="car_control_.odometer_start"
      >
      </base-input>
      <el-row type="flex" justify="start">
        <h4>Observações:</h4>
      </el-row>
      <el-input type="textarea" v-model="car_control_.obs"></el-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import {
  notifySuccess,
  notifyError,
  messageOnCenter,
} from "../../utils/notifiers";
import CarService from "../../services/cars";
import DriverService from "../../services/drivers";
import PartnerService from "../../services/partners";

export default {
  props: ["vehicle", "control", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      car_: this.vehicle || {},
      isLoadingSave: false,
      car_control_: {},
      cars: null,
      drivers: null,
      providers: null,
      categories: null,
      control_categories: [],
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Uma semana atrás",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },
  mounted() {
    this.fetchCars(true);
  },
  watch: {
    vehicle(v) {
      if (v) {
        this.car_control_.car_id = v.uid;
        this.car_ = v;
      }

      this.fetchCars(true);
    },
    control(v) {
      this.car_control_ = v || {};

      if (v?.provider) {
        this.car_control_.provider_id = v.provider.uid;
        this.providers = [v.provider];
      }

      if (v?.controlCategory) {
        this.car_control_.control_category_id = v.controlCategory.uid;
        this.control_categories = [v.controlCategory];
      }

      if (v?.driver) {
        this.car_control_.driver_id = v.driver.uid;
        this.drivers = [v.driver];
      }

      if (v?.car) {
        this.car_control_.car_id = v.car.uid;
        this.cars = [v.car];
      }
    },
  },
  computed: {
    Providers() {
      return this.providers || [];
    },
    ExpirationOptions() {
      return [
        { name: "Por tempo", value: "time" },
        { name: "Por quilometragem", value: "odometer" },
      ];
    },
    Drivers() {
      return this.drivers || [];
    },
    Vehicles() {
      return this.cars || [];
    },
    ControlCategories() {
      return this.control_categories;
    },
    VehicleObsCategories() {
      return ["Viagem", "Manutenção", "Informação"];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async fetchProviders(v) {
      if (v) {
        const { partners } = await PartnerService().index({
          is_provider: true,
          searchName: v,
        });

        if (partners) this.providers = partners;
      }
    },
    async save() {
      this.isLoadingSave = true;
      return this.control ? this.updateCarControl() : this.createCarControl();
    },
    async fetchControlCategories(v) {
      if (v) {
        const { categories } = await CarService().ControlCategory().index();

        if (categories) this.control_categories = categories;
      }
    },
    disableDate(date, field) {
      if (field === "start")
        return new Date(date) > new Date(this.car_control_.complete_at);
      else return new Date(date) < new Date(this.car_control_.start_at);
    },
    async checkIsValidOdometer() {
      const {
        controls: { data: controls },
      } = await CarService(this.car_.uid)
        .Control()
        .index({ limit: 1, pagination: 1 });

      if (controls?.length) {
        const [lastControl] = controls;

        if (Number(this.car_control_.odometer) < Number(lastControl.odometer)) {
          messageOnCenter(
            "Odômetro incorreto",
            "Existe um registro com um odômetro superior ao informado para este veículo."
          );
          return false;
        } else return true;
      }

      return true;
    },
    async createCarControl() {
      if (!(await this.checkIsValidOdometer())) return;

      const { control, error } = await CarService(this.car_?.uid)
        .Control()
        .create(this.car_control_);

      if (control) {
        notifySuccess(control.message);
        this.$emit("should-update");
        this.shouldShow = false;
      } else notifyError(error.message);
    },
    async fetchCars(v) {
      if (v) {
        const { cars } = await CarService().index();
        if (cars) this.cars = cars;
      }
    },
    async fetchDrivers(v) {
      if (v) {
        const { drivers } = (this.drivers = await DriverService().index());
        if (drivers) this.drivers = drivers;
      }
    },
    async updateCarControl() {
      if (!(await this.checkIsValidOdometer())) return;

      if (!this.car_control_.odometer_end)
        this.car_control_.odometer_end = this.car_control_.odometer_start;

      const { control, error } = await CarService(this.car_control_?.car?.uid)
        .Control(this.car_control_?.uid)
        .update(this.car_control_);

      if (control) {
        notifySuccess(control.message);
        this.$emit("should-update");
        this.shouldShow = false;
      } else notifyError(error.message);
    },
  },
  name: "CarControlModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>