<template>
  <el-dialog title="Categorias de Controle de Veículo" v-model="shouldShow">
    <div v-if="showCategories">
      <el-table striped :data="Categories">
        <el-table-column prop="name" label="nome"></el-table-column>
        <el-table-column label="ações">
          <template #default="cat">
            <el-row type="flex" justify="end">
              <el-button-group>
                <el-button
                  onlyIcon
                  type="primary"
                  circle
                  @click="showCategoryForm(cat.row)"
                >
                  <i class="el-icon-edit"></i
                ></el-button>
                <el-popconfirm
                  @confirm="deleteCategory(cat.row)"
                  title="
              Remover a categoria?
            "
                  ><template #reference>
                    <el-button onlyIcon type="danger" circle>
                      <i class="el-icon-delete"></i></el-button></template
                ></el-popconfirm>
              </el-button-group> </el-row
          ></template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <base-input label="Nome:" v-model="category.name"> </base-input>
    </div>
    <template #footer>
      <el-row justify="end" type="flex" v-if="showCategories">
        <el-button type="text" class="button" @click="showCategoryForm()"
          >Adicionar categoria</el-button
        >
      </el-row>
      <el-row justify="space-between" type="flex" v-else>
        <el-button type="warning" class="button" @click="showCategories = true"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="save">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import CarService from "../../services/cars";
import { notifySuccess, notifyError } from "../../utils/notifiers";

export default {
  props: ["showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      categories: null,
      category: {},
      showCategories: true,
    };
  },
  computed: {
    Categories() {
      return this.categories || [];
    },
    isNew() {
      return !("uid" in this.category);
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    showCategoryForm(category = {}) {
      this.showCategories = false;
      this.category = category;
    },
    save() {
      this.isLoadingSave = true;
      return this.isNew
        ? this.createControlCategory()
        : this.updateControlCategory();
    },
    async createControlCategory() {
      const { category, error } = await CarService()
        .ControlCategory()
        .create(this.category);

      if (category) notifySuccess(category.message);
      else notifyError(error.message);

      this.fetchCategories();
    },
    async fetchCategories() {
      const { categories, error } = await CarService()
        .ControlCategory()
        .index();

      if (categories) {
        this.categories = categories;
        this.showCategories = true;
      } else notifyError(error.message);

      this.showCategories = true;
    },
    async deleteCategory(_category) {
      const { category, error } = await CarService()
        .ControlCategory(_category.uid)
        .delete();

      if (category) notifySuccess(category.message);
      else notifyError(error.message);

      this.fetchCategories();
    },
    async updateControlCategory() {
      const { category, error } = await CarService()
        .ControlCategory(this.category?.uid)
        .update(this.category);

      if (category) notifySuccess(category.message);
      else notifyError(error.message);

      this.fetchCategories();
    },
  },
  name: "ControlCategoryModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>