<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" :gutter="8" justify="space-between" align="center">
        <el-col :md="6" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>{{ $route.name }}</h4></el-row
          ></el-col
        >
        <el-col :md="18" :sm="16" :xs="14">
          <el-row type="flex" justify="end" align="center">
            <el-col :md="10" align="center">
              <el-row type="flex" justify="end" style="margin-top: 5px">
                <el-dropdown
                  @command="(c) => filterControls(c)"
                  size="medium"
                  trigger="click"
                >
                  <el-button type="primary" size="medium">
                    Categoria do Controle: {{ displayPage || ""
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-for="option in PageOptions"
                        :key="option"
                        :command="option"
                      >
                        <li
                          :class="{
                            'el-dropdown-menu__item': true,
                            'no-padding': true,
                            active: displayPage === option,
                          }"
                        >
                          {{ option }}
                        </li>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-row>
            </el-col>
            <el-col :md="8">
              <el-select
                remote
                value-key="uid"
                clearable
                class="m-0"
                :loading="!cars"
                @visible-change="fetchCars"
                @change="selectCar"
                v-model="car"
                no-data-text="Não existem veículos cadastrados"
                size="medium"
              >
                <template #prefix>Veículo:</template>
                <el-option
                  v-for="item in cars"
                  :key="item.uid"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col
              :md="6"
              :style="{ 'padding-top': '5px', 'padding-bottom': '5px' }"
            >
              <el-button-group :style="{ 'align-self': 'center' }">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="openCarControlModal(null)"
                ></el-button>
                <el-button
                  type="plain"
                  size="medium"
                  @click="openControlCategoryModal()"
                  >Categorias</el-button
                >
              </el-button-group></el-col
            >
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      v-if="Controls?.data?.length"
      :cell-style="() => 'text-align:center;'"
      :data="Controls.data"
      :row-class-name="(r) => (r.complete_at ? '' : 'warning-row')"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="driver.employee.name" label="motorista">
      </el-table-column>
      <el-table-column
        prop="car.pane"
        label="veículo"
        :width="100"
      ></el-table-column>
      <el-table-column
        label="horário saída"
        :width="100"
        :formatter="
          (r) => dateFormatterShort.format(new Date(r.start_at || ''))
        "
      >
      </el-table-column>
      <el-table-column
        prop="odometer_start"
        label="odômetro inicial"
        :formatter="
          (r) => integerFormatter.format(Number(r.odometer_start) || 0)
        "
        :width="150"
      ></el-table-column>
      <el-table-column
        v-if="shouldShowStartEndOdometer()"
        prop="odometer_end"
        :formatter="(r) => integerFormatter.format(Number(r.odometer_end) || 0)"
        label="odômetro final"
      ></el-table-column>
      <el-table-column
        prop="obs"
        label="observação"
        v-if="displayPage != 'Viagem'"
      ></el-table-column>

      <el-table-column
        v-if="displayPage === 'Viagem'"
        label="km rodado"
        :formatter="
          (r) =>
            `${integerFormatter.format(
              Math.max(
                Number(r?.odometer_end) - Number(r?.odometer_start) || 0,
                0
              )
            )} kms.`
        "
      ></el-table-column>

      <el-table-column label="ações" :width="130">
        <template #default="supply">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openCarControlModal(supply.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openCarControlExecutionInfoModal(supply.row)"
              >
                <i class="el-icon-info"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteCarControl(supply.row)"
                title="
              Remover ordem de abastecimento?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-empty v-else description="O servidor não retornou dados"> </el-empty>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="Controls?.lastPage"
    >
    </el-pagination>
    <car-control-modal
      :showModal="showCarControlModal"
      @close-modal="showCarControlModal = false"
      @should-update="fetchCarControls"
      :vehicle="car"
      :control="car_control"
    ></car-control-modal>
    <car-control-execution-info-modal
      :showModal="showCarControlExecutionInfoModal"
      @close-modal="showCarControlExecutionInfoModal = false"
      @should-update="fetchCarControls"
      :carControl="car_control"
    ></car-control-execution-info-modal>
    <control-category-modal
      :showModal="showControlCategoryModal"
      @should-update="fetchCarControls"
    ></control-category-modal>
  </el-card>
</template>

<script>
import { notifySuccess, notifyError } from "../utils/notifiers";
import CarService from "../services/cars";
import CarControlModal from "./modals/CarControlModal.vue";
import ControlCategoryModal from "./modals/ControlCategoryModal.vue";
import CarControlExecutionInfoModal from "./modals/CarControlExecutionInfoModal.vue";
import {
  numberFormatter,
  currencyFormatter,
  dateFormatter,
  dateFormatterShort,
  integerFormatter,
} from "../utils/formatters";
export default {
  name: "VehicleControls",
  data: () => ({
    displayPage: "Manutenção",
    isLoading: false,
    showCarControlModal: false,
    showControlCategoryModal: false,
    controls: [],
    currentPage: 1,
    car_control: null,
    cars: null,
    car: null,
    showCarControlExecutionInfoModal: false,
    numberFormatter,
    currencyFormatter,
    dateFormatter,
    dateFormatterShort,
    integerFormatter,
  }),
  watch: {
    currentPage() {
      this.fetchCarControls();
    },
  },
  components: {
    CarControlModal,
    ControlCategoryModal,
    CarControlExecutionInfoModal,
  },
  mounted() {
    this.fetchCarControls();
  },
  methods: {
    shouldShowStartEndOdometer() {
      return this.displayPage === "Viagem";
    },
    async fetchCarControls() {
      this.isLoading = true;
      const filterByPage =
        this.displayPage && this.displayPage !== "Tudo" ? this.displayPage : "";
      const { controls } = await CarService(this.car?.uid)
        .Control()
        .index({ category: filterByPage });

      if (controls) this.controls = controls;

      this.isLoading = false;
    },
    openControlCategoryModal() {
      this.showControlCategoryModal = true;
    },
    filterControls(filter) {
      this.currentPage = 1;

      this.displayPage = filter;

      this.fetchCarControls(this.displayPage);
    },
    async fetchCars(v) {
      if (v) {
        const { cars } = await CarService().index();
        if (cars) this.cars = cars;
      }
    },
    openCarControlExecutionInfoModal(v) {
      this.car_control = v;
      this.showCarControlExecutionInfoModal = true;
    },
    openCarControlModal(v) {
      this.car_control = v;
      if (v?.car) this.car = v?.car;
      this.showCarControlModal = true;
    },
    async deleteCarControl(_control) {
      if (_control) {
        const { control, error } = await CarService(_control.car?.uid)
          .Control(_control.uid)
          .delete();

        if (control) notifySuccess(control.message);
        else notifyError(error.message);

        this.fetchCarControls();
      }
    },
    selectCar(v) {
      this.car = v;
      this.fetchCarControls();
    },
  },
  computed: {
    PageOptions() {
      return ["Viagem", "Manutenção", "Informação", "Tudo"];
    },
    Controls() {
      return this.controls || {};
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
.m-0 {
  margin-top: 0px !important;
}
li.active {
  background-color: #409eff;
  color: white;
  padding-left: 17px;
}
li.no-padding {
  margin-left: -17px !important;
  margin-right: -17px !important;
}
</style>
<style>
.warning-row {
  background-color: #fff9c4 !important;
}
</style>